import { env } from 'src/env.mjs';
import { getUserToken, getUserUuid } from '@get-mosh/msh-shared';
import { cookies as nextCookies } from 'next/headers';

export function getAuthCookies() {
    // Workaround for https://github.com/vercel/next.js/issues/49757
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const cookies: typeof nextCookies = require('next/headers').cookies;

    const token =
        typeof window === 'undefined'
            ? cookies().get(env.NEXT_PUBLIC_COOKIE_PREFIX + 'mosh_ut_v2')?.value
            : getUserToken();
    const uuid =
        typeof window === 'undefined'
            ? cookies().get(env.NEXT_PUBLIC_COOKIE_PREFIX + 'mosh_uuid')?.value
            : getUserUuid();

    return {
        token,
        uuid,
    };
}

export function getIsLoggedIn() {
    return !!(getAuthCookies().token && getAuthCookies().uuid);
}
