import ApiRoutes from 'src/constants/ApiRoutes';
import { useQuery } from '@tanstack/react-query';
import { UserResponse } from './types';
import { getHeaders } from '@get-mosh/msh-shared';
import { ApiError } from 'src/types/Api';
import { getAuthCookies } from 'src/helpers/authUtils';

export async function getUser(): Promise<UserResponse | null> {
    const { token, uuid } = getAuthCookies();

    if (!token || !uuid) {
        return null;
    }

    const headers = getHeaders();

    headers.set('Authorization', `Bearer ${token}`);

    const res = await fetch(ApiRoutes.USER(uuid), {
        method: 'GET',
        headers,
    });

    const data = await res.json();

    if (!res.ok) {
        throw data as ApiError;
    }

    return data as UserResponse;
}

export const useUser = () => {
    return useQuery<UserResponse | null, ApiError>({
        queryKey: ['User'],
        queryFn: () => getUser(),
    });
};
