import { MutableRefObject, useEffect } from 'react';

const useClickOutside = (
    ref: MutableRefObject<HTMLElement | null>,
    callback: () => void,
) => {
    const handleClick = (e: { target: EventTarget | null }) => {
        if (ref.current && !ref.current?.contains(e.target as Node)) {
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};

export default useClickOutside;
