/***
* Segment naming convention - object-action framework.
* First, choose your objects (e.g., Product, Application, etc.).
* Then define actions your customers can perform on those objects (e.g., Viewed, Installed, etc.).
* Examples: Product Viewed or Application Installed.

* Proper Case for events and snake_case for properties.

* Objects: product, account, cart, order,
* Actions: clicked, viewed, added, favorited, created, updated, shared, saved, completed, cancelled, opened, deleted, removed
 */

export default {
    // Hair category page
    BUTTON_START_MY_FREE_CONSULT_CLICKED:
        'Button Start my free consult Clicked',
    BUTTON_VIEW_SPRAY_OPTION_CLICKED: "Button 'View spray option' Clicked",
    BUTTON_LEARN_MORE_CLICKED: 'Button Learn more Clicked',
    BUTTON_GET_THE_GUIDE_CLICKED: 'Button Get the guide Clicked',

    // Weight Loss
    BMI_CALCULATOR_RESULT: 'BMI Calculation',
    // Homepage & nav
    CATEGORY_TILE_CLICKED: 'Category Tile Clicked',
    MAIN_NAVIGATION_ITEM_CLICKED: 'Main Navigation Item Clicked',
    HAMBURGER_MENU_ITEM_CLICKED: 'Hamburger Menu Item Clicked',
    WHAT_WE_TREAT_MENU_ITEM_CLICKED: 'What we treat Item Clicked',
    TOP_BANNER_CLICKED: 'Top Banner Clicked',
    CATEGORY_SUBPAGE_LINK_CLICKED: 'Category Subpage Link Clicked',
    BUTTON_FIND_MY_TREATMENT_CLICKED: 'Button Find my treatment Clicked',
    BUTTON_MESSAGE_YOUR_DOCTOR_CLICKED: 'Button Message your doctor Clicked',
    BUTTON_READ_MORE_TRUSTPILOT_REVIEWS_CLICKED:
        'Button Read more reviews Clicked',
    BUTTON_SEE_IF_YOURE_ELIGIBLE: "Button See if you're eligible Clicked",

    BUTTON_START_QUIZ: 'Button Start Quiz clicked',
    BUTTON_BOOK_CONSULT: 'Button Book Consult clicked',
    BUTTON_PLAY_VIDEO_CLICKED: 'Button Play Video clicked',
    BUTTON_REQUEST_A_QUOTE: 'Button Request A Quote clicked',

    // General events
    TAB_CLICKED: 'Tab Clicked',
    ACCORDION_EXPANDED: 'Accordion Expanded',
    MODAL_OPENED: 'Modal opened',
    BUTTON_CLICKED: 'Button Clicked',
    EXPERIMENT_VIEWED: 'Experiment Viewed',

    // Plan page
    PORTAL_BUTTON_CLICKED: 'Portal Button Clicked',

    // Checkout
    SALES_COMPLETED: 'Sales Completed',
    PAYMENT_METHOD_SELECTED: 'Payment Method Selected',

    // Quiz
    QUIZ_BUTTON_CLICKED: 'Quiz Button Clicked',
};
